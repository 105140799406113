<template>
  <div>
    <b-overlay :show="isShowLoading">
      <validation-observer ref="validateForm" #default="{invalid}">
        <b-form @submit.prevent="saveData">

          <b-card :title="$t('user.changePassword')">
            <b-row>
              <b-col md="6">
                <b-form-group :label="$t('user.oldPassword') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('user.oldPassword')" rules="required|min:8">
                    <b-form-input type="password" v-model="userOldPassword" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6"></b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.password') + '*'">
                  <validation-provider #default="{ errors }" name="password" rules="required|min:8|integer">
                    <b-form-input type="password" v-model="userPassword" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.rePassword') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('user.rePassword')" rules="required|confirmed:password">
                    <b-form-input type="password" v-model="userRePassword" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-row>
            <b-col class="text-center">
              <b-button type="submit" variant="success" class="my-1" :disabled="isBtnDisabled">
                <b-spinner small v-if="isBtnDisabled"/>
                <feather-icon icon="SaveIcon" v-else/>
                {{ $t('general.btnSubmit') }}
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>

    </b-overlay>
  </div>

</template>

<script>
import {BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BSpinner, BForm, BFormCheckbox, BFormFile, BButton, BOverlay} from 'bootstrap-vue'
import {required, min, confirmed, integer, exact_length} from '@validations'
import tableMixins from "@/mixins/tableMixins"
import masterMixins from "@/mixins/masterMixins"
import {UserService, ProfileService} from "@/services"

const userService = new UserService()
const profileService = new ProfileService()

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BFormFile,
    BButton,
    BSpinner,
    BOverlay,
  },
  mixins: [tableMixins, masterMixins],
  async created() {
    this.id = this.userData.userId

    this.isShowLoading = false
  },
  data: () => ({
    isShowLoading: true,
    isBtnDisabled: false,
    id: '',
    userData: JSON.parse(localStorage.getItem('userData')),
    userPassword: '',
    userRePassword: '',
    userOldPassword: '',
  }),
  methods: {
    async saveData() {
      this.$refs.validateForm.validate()

      this.$refs.validateForm.validate().then(async success => {
        if (success) {
          this.isBtnDisabled = true

          let actionData = {
            userOldPassword: this.userOldPassword,
            userPassword: this.userPassword,
          }

          let saveResult = await profileService.changePassword(actionData)

          if (saveResult.isSuccess) {
            this.$store.commit('main/setToastSuccess', {title: 'Save success', text: ''})
            this.isBtnDisabled = false
            await this.$router.push({name: 'home'})
          } else {
            this.isBtnDisabled = false
            this.$store.commit('main/setToastError', saveResult.data)
          }
        }
      })
    },
  }
}
</script>

